import './style.css';
import {Map, Overlay, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {useGeographic} from "ol/proj";
import {KML} from "ol/format";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Fill, Stroke, Style} from "ol/style";
import CircleStyle from "ol/style/Circle";

useGeographic();

// Farm display
const farmStyle = new Style({
  image: new CircleStyle({
    radius: 10,
    fill: new Fill({
      color: 'orange',
    }),
    stroke: new Stroke({
      color: 'black',
      width: 1,
    }),
  }),
})
const selectedFarmStyle = new Style({
  image: new CircleStyle({
    radius: 10,
    fill: new Fill({
      color: 'red',
    }),
    stroke: new Stroke({
      color: 'black',
      width: 1,
    }),
  }),
})
const farmVector = new VectorLayer({
  source: new VectorSource({
    url: '/static/bcsalmon/map_js/farm.kml',
    format: new KML({
      extractStyles: false,
    }),
  }),
  style: farmStyle,
})

// wild sampling
const wildStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'lightgreen',
    }),
    stroke: new Stroke({
      color: 'black',
      width: 1,
    }),
  }),
})
const selectedWildStyle = new Style({
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: 'yellow',
    }),
    stroke: new Stroke({
      color: 'black',
      width: 1,
    }),
  }),
})
const wildVector = new VectorLayer({
  source: new VectorSource({
    url: '/static/bcsalmon/map_js/salmon_coast.kml',
    format: new KML({
      extractStyles: false,
    }),
  }),
  style: wildStyle,
})

const map = new Map({
  target: 'map',
  layers: [
      new TileLayer({
        source: new OSM()
      }),
      farmVector,
      wildVector,
  ],
  view: new View({
    center: [-128, 50.9],
    zoom: 5,
    minZoom: 5,
    extent: [-140, 47, -120, 59],
  }),
});

// Tooltip
const tooltip = document.getElementById('tooltip');
const overlay = new Overlay({
  element: tooltip,
  offset: [10, 0],
  positioning: 'bottom-left',
});
map.addOverlay(overlay);

function displayTooltip(evt) {
  const pixel = evt.pixel;
  const feature = map.forEachFeatureAtPixel(pixel, function(feature) {
    return feature;
  });
  tooltip.style.display = feature ? '' : 'none';
  if (feature) {
    overlay.setPosition(evt.coordinate);
    tooltip.innerHTML = feature.get('name');
  }
}
map.on('pointermove', displayTooltip);

// keep a reference to the currently selected site
let selected;
let isSelectedWild;


const farm_url = document.getElementById('farm_info');
function selectSite(evt) {
  const pixel = evt.pixel;
  const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
    return feature;
  });
  // change the style of this one so we know it's selected
  if (selected) {
    // a site was already selected - change it back
    if (isSelectedWild) {
      selected.setStyle(wildStyle);
    } else {
      selected.setStyle(farmStyle);
    }
  }
  selected = feature;
  isSelectedWild = (selected.get('description').startsWith("Wild"));
  if (isSelectedWild) {
    selected.setStyle(selectedWildStyle);
    farm_url.src = 'wild/' + selected.get('name');
  } else {
    selected.setStyle(selectedFarmStyle);
    farm_url.src = 'farm/' + selected.getId();
  }
}
map.on('click', selectSite);
